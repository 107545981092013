import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/students.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Students @ Cross Church"
    SecondText="Connecting the next generation to Jesus."
  >
    <SEO title="Students @ Cross Church" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Students @ Cross Church
    </PageHeaders>
    <HeaderText>Our Mission</HeaderText>
    <ParagraphText>
      The mission of Students at Cross Church is to connect the next generation
      to Jesus. We will do this by plugging students into the Gospel message,
      investing biblical truths into their lives, and encouraging them to get
      others plugged in as well.
    </ParagraphText>
    <HeaderText>Who We Are</HeaderText>
    <ParagraphText>
      Cross Church cares about equipping Middle and High School students to Make
      Jesus Known! Every student service, event, and camp has the ultimate aim
      of connecting students to other students, leaders, and ultimately Jesus!
      Sunday services are a time of scripture centered messages, rewhip, and
      small group discussions helping students grow spiritually and in community
      with each other. Wednesday night gatherings are ideal for students to
      invite friends for a time of high energy games, worship, and teachings
      that answer life's biggest questions.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Wednesdays
    </PageHeaders>
    <HeaderText>Wednesdays</HeaderText>
    <ParagraphText>
      Students at Cross Church meet at 6:30pm Wednesday evenings.
    </ParagraphText>

    <a href="/surprise/parents">
      <PageHeaders
        css={css`
          display: flex;
          margin-top: 4rem;
        `}
      >
        Parent Resources
      </PageHeaders>
    </a>
    <HeaderText></HeaderText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Keep in Touch
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      There is a lot that happens in our Student Ministry that we want you to be
      a part of. Here are a few ways to stay in communication with us!
      <br />
      <br />
      Follow us on Facebook or Instagram (links below).
      <br />
      <br />
      Any other questions, email Kyle{" "}
      <a href="mailto:kyle@crosschurchcares.com">here</a> or sign-up to keep in
      touch below.
    </ParagraphText>
    <iframe
      src="https://cccares.formstack.com/forms/students_surprise"
      title="Students at Cross Church"
      width="100%"
      height="550px"
      frameBorder="0"
    ></iframe>
    <PageHeaders
      css={css`
        margin-top: 4rem;
      `}
    ></PageHeaders>
    <ParagraphText
      css={css`
        margin-top: 2rem;
        text-align: center;
      `}
    >
      <a href="https://www.facebook.com/StudentsAtCrossChurch/">
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            height: 44px;
            fill: #333;
          `}
        >
          <path d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0" />
        </svg>
      </a>
      <a href="https://www.instagram.com/studentsatcrosschurch/">
        <svg
          viewBox="0 0 512 512"
          css={css`
            height: 44px;
            fill: #333;
            margin-left: 2rem;
          `}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m75 512h362c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0" />
          <path d="m256 391c74.4375 0 135-60.5625 135-135s-60.5625-135-135-135-135 60.5625-135 135 60.5625 135 135 135zm0-240c57.898438 0 105 47.101562 105 105s-47.101562 105-105 105-105-47.101562-105-105 47.101562-105 105-105zm0 0" />
          <path d="m406 151c24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45 20.1875 45 45 45zm0-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0" />
        </svg>
      </a>
    </ParagraphText>
  </Layout>
)

export default IndexPage
